import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import AnimatedBlock from "../components/AnimatedBlock";
import YoutubeEmbed from "~/components/YoutubeEmbed";
import {
  gatsbyImageDataPropType,
  contentfulRichTextPropType,
} from "~/propTypes";
import Layout from "~/components/layout/Layout";
import PageSectionList from "~/components/PageSectionList";
import Seo from "~/components/Seo";
import ContentfulRichText from "~/components/ContentfulRichText";
import "./PressRelease.scss";

function PressRelease({ data: { pressRelease, template } }) {
  const imageData = pressRelease.image
    ? getImage(pressRelease.image.gatsbyImageData)
    : undefined;
  return (
    <Layout>
      <Seo data={pressRelease.metadata} />
      <section>
        <div className="container">
          <div className="row">
            <div className="press-post-head">
              <AnimatedBlock order={1}>
                <span className="published">{pressRelease.publishDate}</span>
                <h1>{pressRelease.heading}</h1>
              </AnimatedBlock>
              <AnimatedBlock order={3}>
                <div className="line-yellow small-line-margin" />
              </AnimatedBlock>
              <AnimatedBlock order={5}>
                <p>{pressRelease.excerpt}</p>
              </AnimatedBlock>
              {/* <div className="press-meta-info">
                <span>{pressRelease.authorName}</span>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad-sm">
        <div className="container">
          <div className="row">
            <div className="press-body">
              {imageData ? (
                <div className="press-img">
                  <GatsbyImage
                    image={imageData}
                    alt={pressRelease.image.title}
                  />
                </div>
              ) : null}
              {pressRelease.videoUrl ? (
                <YoutubeEmbed
                  sourceUrl={pressRelease.videoUrl}
                  title={pressRelease.heading}
                />
              ) : null}
              <div className="press-post-content">
                <ContentfulRichText content={pressRelease.content} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {template.sections ? <PageSectionList data={template.sections} /> : null}
    </Layout>
  );
}

PressRelease.propTypes = {
  data: PropTypes.shape({
    pressRelease: PropTypes.shape({
      metadata: Seo.propTypes.data,
      heading: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      publishDate: PropTypes.string.isRequired,
      image: PropTypes.shape({
        title: PropTypes.string.isRequired,
        gatsbyImageData: gatsbyImageDataPropType.isRequired,
      }),
      videoUrl: PropTypes.string,
      content: contentfulRichTextPropType.isRequired,
      authorName: PropTypes.string.isRequired,
    }).isRequired,
    template: PropTypes.shape({
      sections: PageSectionList.propTypes.data,
    }),
  }).isRequired,
};

const query = graphql`
  query PressReleaseQuery($id: String!) {
    pressRelease: contentfulPressRelease(id: { eq: $id }) {
      metadata {
        ...Seo_data
      }
      heading
      publishDate(formatString: "D MMMM YYYY")
      excerpt
      videoUrl
      image {
        title
        gatsbyImageData(
          placeholder: NONE
          backgroundColor: "white"
          layout: FULL_WIDTH
          aspectRatio: 2
          quality: 100
        )
      }
      content {
        raw
      }
      authorName
    }
    template: contentfulPageTemplate(internalId: { eq: "Press release" }) {
      sections {
        ...PageSectionList_data
      }
    }
  }
`;

export { query };
export default PressRelease;
